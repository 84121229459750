<template>
  <div
    v-if="
      $store.state.fastPermissoes.ativo == 'S' &&
        ($store.state.fastPermissoes.administrador == 'S')
    "
  >
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/admin'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/admin')"
              >Modo admin</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >Lives</a>
            </li>
          </ul>
        </nav>
        <!-- /breadcrumb -->
      </div>

      <div class="fd-mode-adm-content mt-4">
        <div class="container">
          <div class="row">
            <div
              id="body_conteudo_modo_admin"
              class="col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0"
            >
              <!-- fd-app-welcome -->
              <section class="fd-app-welcome text-left">
                <h1 class="aluno_font_color">
                  Gerenciamento de
                  <span id="txtDashboardNomePlataforma">Lives</span>
                </h1>
                <img :src="require('@/assets/images/form-effect-01.png')">
              </section>
              <!-- /fd-app-welcome -->
              <div class="col-12 mt-2">
                <div class="row">
                  <div class="col-sm-12 col-md-12 col-lg-8 mt-4 mb-1">
                    <h4 class="aluno_font_color">
                      Lives
                      <span
                        v-if="calendarFiltroAtivo"
                      >do dia {{ calendarFiltroAtivo }}</span>
                      <button
                        class="btn btn-sm btn-secondary ml-2"
                        @click="showModal('modalCalendarioLive')"
                      >
                        <small>
                          <b-icon-filter />
                          Filtro ({{ fastLiveFiltro.length }})
                        </small>
                      </button>
                      <button
                        v-if="calendarFiltroAtivo"
                        class="btn btn-sm btn-secondary ml-2"
                        @click="getFastLive()"
                      >
                        <small> <i class="fas fa-redo" /> Limpar </small>
                      </button>
                    </h4>
                  </div>
                  <div
                    class="col-sm-12 col-md-12 col-lg-4 mt-4 mb-1 text-right"
                  >
                    <button
                      class="btn btn-primary ml-2"
                      @click.prevent="showModal('modalCriarLive')"
                    >
                      <small>+ Criar live</small>
                    </button>
                  </div>

                  <div class="col-12 table-responsive">
                    <table class="table table-sm table-striped">
                      <thead class="thead-dark">
                        <tr>
                          <th class="text-center">
                            #
                          </th>
                          <th>
                            <small
                              class="font-weight-bold"
                            >Título da live</small>
                          </th>
                          <th class="text-center">
                            <small class="font-weight-bold">Data/hora</small>
                          </th>
                          <th class="text-center">
                            <small class="font-weight-bold">Duração</small>
                          </th>
                          <th class="text-center">
                            <small class="font-weight-bold">Tipo</small>
                          </th>
                          <th class="text-center">
                            <small class="font-weight-bold">Status</small>
                          </th>
                          <th class="text-center">
                            <small class="font-weight-bold">Ações</small>
                          </th>
                        </tr>
                      </thead>
                      <tbody v-if="fastLiveFiltro.length">
                        <tr
                          v-for="live in pageOfLive"
                          :key="live.id_live"
                        >
                          <td class="text-center align-middle" />
                          <td class="align-middle">
                            <small>{{ live.titulo_live }}</small>
                          </td>
                          <td class="text-center align-middle">
                            <small>{{
                                     new Date(live.data_live)
                                       .toLocaleString()
                                       .split(" ")[0]
                                   }}
                              {{ live.hora_live }}</small>
                          </td>
                          <td class="text-center align-middle">
                            <small>{{ live.duracao_live }}</small>
                          </td>
                          <td class="text-center align-middle">
                            <small v-if="live.tipo == 'J'">
                              <img
                                v-lazy="
                                  require('@/assets/images/logo_horizontal.png')
                                "
                                style="max-width: 70px"
                              >
                            </small>
                            <small v-else-if="live.tipo == 'Z'">
                              <strong class="text-info">Zoom</strong>
                            </small>
                            <small v-else-if="live.tipo == 'M'">
                              <strong class="text-warning">Google Meet</strong>
                            </small>
                            <small v-else-if="live.tipo == 'A'">
                              <strong class="text-success">Telemedicina</strong>
                            </small>
                          </td>
                          <td class="text-center align-middle">
                            <small
                              v-if="live.status == 'A'"
                              class="text-success"
                            >Agendada</small>
                            <small
                              v-else-if="live.status == 'E'"
                              class="text-warning"
                            >Em execução</small>
                            <small
                              v-else-if="live.status == 'C'"
                              class="text-danger"
                            >Cancelada</small>
                            <small
                              v-else-if="live.status == 'F'"
                              class="text-secondary"
                            >Finalizada</small>
                          </td>
                          <td class="text-center align-middle">
                            <a
                              v-if="live.status == 'A' || live.status == 'E'"
                              class="btn btn-sm btn-success pt-0 pb-0 mr-2"
                              :href="'/live/' + live.id_live"
                            >
                              <small>Acessar</small>
                            </a>
                            <button
                              v-if="live.status == 'E' || live.status == 'F'"
                              class="btn btn-sm btn-success pt-0 pb-0 mr-2"
                              @click="exibeModalEstatisticasLive(live)"
                            >
                              <small>Estatísticas</small>
                            </button>
                            <button
                              v-if="live.status == 'A' || live.status == 'E'"
                              class="btn btn-sm btn-primary pt-0 pb-0 mr-2"
                              @click="exibeModalEditarLive(live)"
                            >
                              <small>Editar</small>
                            </button>
                            <button
                              v-if="live.status == 'F' && live.video_registro"
                              class="btn btn-sm btn-success pt-0 pb-0 mr-2"
                              @click="exibeModalGravacaoLive(live)"
                            >
                              <small>Exibir gravação</small>
                            </button>
                            <button
                              v-if="live.status == 'F' && !live.video_registro"
                              class="btn btn-sm btn-primary pt-0 pb-0 mr-2"
                              @click="exibeModalGravacaoLive(live)"
                            >
                              <small>Inserir gravação</small>
                            </button>
                            <button
                              v-if="
                                live.status == 'A' ||
                                  live.status == 'E' ||
                                  live.status == 'F'
                              "
                              class="btn btn-sm btn-primary pt-0 pb-0 mr-2"
                              @click="exibeModalAnexosLive(live)"
                            >
                              <small>Anexos</small>
                            </button>
                            <button
                              v-if="live.status == 'A' || live.status == 'E'"
                              class="btn btn-sm btn-danger pt-0 pb-0 mr-2"
                              @click="exibeModalCancelarLive(live)"
                            >
                              <small>Cancelar</small>
                            </button>
                            <button
                              v-if="live.status == 'E'"
                              class="btn btn-sm btn-danger pt-0 pb-0 mr-2"
                              @click="exibeModalFinalizarLive(live)"
                            >
                              <small>Finalizar</small>
                            </button>

                            <button
                              class="btn btn-sm btn-danger pt-0 pb-0"
                              @click="exibeModalExcluirLive(live)"
                            >
                              <small>Excluir</small>
                            </button>
                          </td>
                        </tr>
                      </tbody>
                      <tbody v-else>
                        <tr class="text-center">
                          <td colspan="7">
                            Nenhuma live encontrada
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="col-12 text-center mt-4">
                  <Pagination
                    :page-size="10"
                    :items="fastLiveFiltro"
                    @changePage="pageOfLive = $event"
                  />
                </div>
              </div>
              <!-- fd-app-meus-cursos -->
              <div class="mt-5 mb-5">
                <a
                  id="btn_voltar_modo_admin"
                  class="btn btn-red-hollow"
                  :href="
                    '/plataforma/' + $route.params.id_plataforma + '/admin'
                  "
                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/admin')"
                >
                  <b-icon-arrow-return-left />
                  Voltar
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modals -->
    <modal
      name="modalCriarLive"
      :shift-y="0.1"
      height="auto"
      :width="modalWidth"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Nova live</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalCriarLive')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-3">
              <label for>Título da live</label>
              <input
                v-model="fastLiveNova.titulo_live"
                type="text"
                class="form-control"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6">
              <label for>Detalhes da live</label>
              <textarea
                v-model="fastLiveNova.detalhes"
                class="form-control"
              />
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3">
              <label>Tipo</label>
              <select
                v-model="fastLiveNova.tipo"
                class="form-control"
              >
                <option value="J">
                  FastEAD (Nova)
                </option>
                <option value="Z">
                  Zoom
                </option>
                <option value="M">
                  Google Meet
                </option>
                <option value="A">
                  Telemedicina (tarifada)
                </option>
              </select>
            </div>
            <div
              v-if="fastLiveNova.tipo == 'M'"
              class="col-12"
            >
              <label for>URL do Google Meet</label>
              <input
                v-model="fastLiveNova.API_TOKEN"
                type="text"
                class="form-control"
                placeholder="Gere a URL do Google Meet e insira aqui"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mt-4">
              <label for>Data da live</label>
              <input
                v-model="fastLiveNova.data_live"
                type="date"
                class="form-control"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mt-4">
              <label for>Hora da live</label>
              <input
                v-model="fastLiveNova.hora_live"
                type="time"
                class="form-control"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mt-4">
              <label for>Duração da live</label>
              <input
                v-model="fastLiveNova.duracao_live"
                type="time"
                class="form-control"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mt-4">
              <label for>Acesso</label>
              <select
                v-model="fastLiveNova.acesso"
                class="form-control"
              >
                <option value="R">
                  Restrito
                </option>
              </select>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
              <label
                for
              >Participantes ({{ fastLiveNova.participantes.length }})</label>
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-4 pb-2">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Filtrar por nome"
                    @keyup="filtraUsuario"
                  >
                </div>

                <div class="col-sm-12 col-md-12 col-lg-12 table-responsive">
                  <table class="table table-sm table-striped">
                    <thead class="thead-dark">
                      <tr>
                        <th scope="col">
                          Nome do usuário
                        </th>
                        <th scope="col">
                          Email
                        </th>
                        <th
                          scope="col"
                          class="text-center"
                        >
                          Ativo
                        </th>
                        <th
                          class="text-center"
                          scope="col"
                        >
                          Ações
                        </th>
                      </tr>
                    </thead>
                    <tbody v-if="pageOfUsuarios.length">
                      <tr
                        v-for="u in pageOfUsuarios"
                        :key="u.email"
                      >
                        <td>{{ u.nome_usuario }}</td>
                        <td>{{ u.email }}</td>
                        <td class="text-center">
                          <span class="text-success">{{
                            u.ativo == "S" ? "Ativo" : ""
                          }}</span>
                          <span class="text-warning">{{
                            u.ativo != "S" ? "Não ativo" : ""
                          }}</span>
                        </td>
                        <td class="text-center">
                          <button
                            v-if="
                              u.ativo == 'S' &&
                                !verificaUsuarioIncluido(u.id_usuario)
                            "
                            class="btn btn-sm btn-success btn-block"
                            title="Inserir participante"
                            @click="adicionaParticipantesLive(u)"
                          >
                            <small>
                              <b-icon-person-plus-fill /> Adicionar
                            </small>
                          </button>
                          <button
                            v-else-if="
                              u.ativo == 'S' &&
                                verificaUsuarioIncluido(u.id_usuario)
                            "
                            class="btn btn-sm btn-danger btn-block"
                            title="Remover participante"
                            @click="removeParticipantesLive(u.id_usuario)"
                          >
                            <small>
                              <b-icon-person-dash-fill /> Remover
                            </small>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-else>
                      <tr>
                        <td
                          class="text-center"
                          colspan="4"
                        >
                          Nenhum usuário disponível
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="col-12 text-center">
                  <Pagination
                    :page-size="5"
                    :items="usuariosFiltro"
                    @changePage="pageOfUsuarios = $event"
                  />
                </div>
                <div class="col-sm-12 col-md-12 col-lg-4 pb-2">
                  <label for>Tutor da live</label>
                  <select
                    v-if="fastLiveNova.participantes.length"
                    v-model="fastLiveNova.id_usuario_tutor"
                    class="form-control"
                  >
                    <option
                      v-for="participante in fastLiveNova.participantes"
                      :key="participante.id_usuario"
                      :value="participante.id_usuario"
                    >
                      {{ participante.nome_usuario }}
                    </option>
                  </select>
                  <select
                    v-else
                    class="form-control"
                    style="
                      background: #eee;
                      pointer-events: none;
                      touch-action: none;
                    "
                  >
                    <option>Escolha um participante</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-primary"
                  @click="criarLive()"
                >
                  Agendar live
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalEditarLive"
      :shift-y="0.1"
      height="auto"
      :width="modalWidth"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Editar live</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalEditarLive')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-3">
              <label for>Título da live</label>
              <input
                v-model="fastLiveEditar.titulo_live"
                type="text"
                class="form-control"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6">
              <label for>Detalhes da live</label>
              <textarea
                v-model="fastLiveEditar.detalhes"
                class="form-control"
              />
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3">
              <label>Tipo</label>
              <select
                v-model="fastLiveEditar.tipo"
                class="form-control"
              >
                <option value="J">
                  FastEAD (Nova)
                </option>
                <option value="M">
                  Google Meet
                </option>
                <option value="Z">
                  Zoom
                </option>
                <option value="A">
                  Telemedicina (tarifada)
                </option>
              </select>
            </div>
            <div
              v-if="fastLiveEditar.tipo == 'M'"
              class="col-12"
            >
              <label for>URL do Google Meet</label>
              <input
                v-model="fastLiveEditar.API_TOKEN"
                type="text"
                class="form-control"
                placeholder="Gere a URL do Google Meet e insira aqui"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mt-4">
              <label
                for
              >Data da live ({{
                new Date(fastLiveEditar.data_live)
                  .toLocaleString()
                  .split(" ")[0]
              }})</label>

              <input
                v-model="fastLiveEditar.data_live"
                type="date"
                class="form-control"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mt-4">
              <label for>Hora da live</label>
              <input
                v-model="fastLiveEditar.hora_live"
                type="time"
                class="form-control"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mt-4">
              <label for>Duração da live</label>
              <input
                v-model="fastLiveEditar.duracao_live"
                type="time"
                class="form-control"
              >
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mt-4">
              <label for>Acesso</label>
              <select
                v-model="fastLiveEditar.acesso"
                class="form-control"
              >
                <option value="R">
                  Restrito
                </option>
              </select>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
              <label
                for
              >Participantes ({{
                fastLiveEditar.participantes.length
              }})</label>
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-4 pb-2">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Filtrar por nome"
                    @keyup="filtraUsuarioEditar"
                  >
                </div>

                <div class="col-sm-12 col-md-12 col-lg-12 table-responsive">
                  <table class="table table-sm table-striped">
                    <thead class="thead-dark">
                      <tr>
                        <th scope="col">
                          Nome do usuário
                        </th>
                        <th scope="col">
                          Email
                        </th>
                        <th
                          scope="col"
                          class="text-center"
                        >
                          Ativo
                        </th>
                        <th
                          class="text-center"
                          scope="col"
                        >
                          Ações
                        </th>
                      </tr>
                    </thead>
                    <tbody v-if="pageOfUsuariosEditar.length">
                      <tr
                        v-for="u in pageOfUsuariosEditar"
                        :key="u.email"
                      >
                        <td>{{ u.nome_usuario }}</td>
                        <td>{{ u.email }}</td>
                        <td class="text-center">
                          <span class="text-success">{{
                            u.ativo == "S" ? "Ativo" : ""
                          }}</span>
                          <span class="text-warning">{{
                            u.ativo != "S" ? "Não ativo" : ""
                          }}</span>
                        </td>
                        <td class="text-center">
                          <button
                            v-if="
                              u.ativo == 'S' &&
                                !verificaUsuarioIncluidoEditar(u.id_usuario)
                            "
                            class="btn btn-sm btn-success btn-block"
                            title="Inserir participante"
                            @click="adicionaParticipantesLiveEditar(u)"
                          >
                            <small>
                              <b-icon-person-plus-fill /> Adicionar
                            </small>
                          </button>
                          <button
                            v-else-if="
                              u.ativo == 'S' &&
                                verificaUsuarioIncluidoEditar(u.id_usuario)
                            "
                            class="btn btn-sm btn-danger btn-block"
                            title="Remover participante"
                            @click="removeParticipantesLiveEditar(u.id_usuario)"
                          >
                            <small>
                              <b-icon-person-dash-fill /> Remover
                            </small>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-else>
                      <tr>
                        <td
                          class="text-center"
                          colspan="4"
                        >
                          Nenhum usuário disponível
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="col-12 text-center">
                  <Pagination
                    :page-size="5"
                    :items="usuariosFiltroEditar"
                    @changePage="pageOfUsuariosEditar = $event"
                  />
                </div>
                <div class="col-sm-12 col-md-12 col-lg-4 pb-2">
                  <label for>Tutor da live</label>
                  <select
                    v-if="fastLiveEditar.participantes.length"
                    v-model="fastLiveEditar.id_usuario_tutor"
                    class="form-control"
                  >
                    <option
                      v-for="participante in fastLiveEditar.participantes"
                      :key="participante.id_usuario"
                      :value="participante.id_usuario"
                      :checked="
                        participante.id_usuario ==
                          fastLiveEditar.id_usuario_tutor
                          ? true
                          : false
                      "
                    >
                      {{ participante.nome_usuario }}
                    </option>
                  </select>
                  <select
                    v-else
                    class="form-control"
                    style="
                      background: #eee;
                      pointer-events: none;
                      touch-action: none;
                    "
                  >
                    <option>Escolha um participante</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-primary"
                  @click="editarLive()"
                >
                  Salvar alterações
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalExcluirLive"
      :scrollable="true"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Excluir live?</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalExcluirLive')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-danger"
                  @click="excluirLive()"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalFinalizarLive"
      :scrollable="true"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Finalizar a live?</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalFinalizarLive')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-danger"
                  @click="finalizarLive('F')"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalCancelarLive"
      :scrollable="true"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Cancelar a live?</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalCancelarLive')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-danger"
                  @click="finalizarLive('C')"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalCalendarioLive"
      :scrollable="true"
      :max-width="992"
      width="100%"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
      :adaptive="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Calendário de lives</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalCalendarioLive')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12 mt-2">
              <span
                class="text-warning font-weight-bold float-left"
              >Dias com lives agendadas</span>
              <span
                class="text-success font-weight-bold float-right"
              >Dias com lives em execução</span>
            </div>
          </div>
          <div class="row">
            <div class="col-12 mt-2">
              <FunctionalCalendar
                v-model="calendarData"
                :configs="calendarConfigs"
                @dayClicked="filtraDiaLive"
              />
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalEstatisticasLive"
      :shift-y="0.1"
      height="auto"
      :width="modalWidth"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4 v-if="fastEstatisticasLive.length">
            Estatísticas da live - {{ fastEstatisticasLive[0].titulo_live }} -
            {{
              new Date(fastEstatisticasLive[0].data_live).toLocaleDateString()
            }}
            às {{ fastEstatisticasLive[0].hora_live }}
          </h4>
          <h4 v-else>
            Estatísticas da live
          </h4>
          <h6 class="text-info mt-2 mb-2">
            {{ retornaTotalUsuarios() }}
          </h6>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalEstatisticasLive')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 table-responsive">
              <table class="table table-striped">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col" />
                    <th scope="col">
                      Usuário
                    </th>
                    <th scope="col">
                      Email
                    </th>
                    <th
                      scope="col"
                      class="text-center"
                    >
                      Tutor
                    </th>
                    <th
                      scope="col"
                      class="text-center"
                    >
                      Entrada
                    </th>
                    <th
                      scope="col"
                      class="text-center"
                    >
                      Saída
                    </th>
                    <th
                      scope="col"
                      class="text-center"
                    >
                      Dispositivo
                    </th>
                    <th
                      scope="col"
                      class="text-center"
                    >
                      Navegador
                    </th>
                    <th
                      scope="col"
                      class="text-center"
                    >
                      Endereço IP
                    </th>
                    <th
                      scope="col"
                      class="text-center"
                    >
                      Ações
                    </th>
                  </tr>
                </thead>
                <tbody v-if="pageOfEstatisticasLive.length">
                  <tr
                    v-for="(usuario, index) in pageOfEstatisticasLive"
                    :key="usuario.id_usuario"
                  >
                    <td class="align-middle">
                      {{ index + 1 }}
                    </td>
                    <td class="align-middle">
                      {{ usuario.nome_usuario }}
                    </td>
                    <td class="align-middle">
                      {{ usuario.email }}
                    </td>
                    <td class="align-middle text-center">
                      <span
                        v-if="usuario.id_usuario == usuario.id_usuario_tutor"
                      >
                        <small class="btn btn-sm btn-success">Sim</small>
                      </span>
                      <span v-else>
                        <small class="btn btn-sm btn-danger">Não</small>
                      </span>
                    </td>
                    <td class="align-middle text-center">
                      <span v-if="usuario.hora_entrou">
                        {{ usuario.hora_entrou.split(" ")[1] }}
                      </span>
                      <span
                        v-else
                        class="text-danger"
                      > Não registrado </span>
                    </td>
                    <td class="align-middle text-center">
                      <span v-if="usuario.hora_saiu">
                        {{ usuario.hora_saiu.split(" ")[1] }}
                      </span>
                      <span
                        v-else
                        class="text-danger"
                      > Não registrado </span>
                    </td>
                    <td class="align-middle text-center">
                      <span v-if="usuario.tipo_dispositivo == 'D'">
                        Desktop
                      </span>
                      <span v-else-if="usuario.tipo_dispositivo == 'M'">
                        Mobile
                      </span>
                      <span
                        v-else
                        class="text-danger"
                      > Não identificado </span>
                    </td>
                    <td class="align-middle text-center">
                      <span v-if="usuario.user_agent">
                        {{ detectaBrowser(usuario.user_agent) }}
                      </span>
                      <span
                        v-else
                        class="text-danger"
                      > Não registrado </span>
                    </td>
                    <td class="align-middle text-center">
                      <span v-if="usuario.endereco_ip">
                        {{ usuario.endereco_ip }}
                      </span>
                      <span
                        v-else
                        class="text-danger"
                      > Não registrado </span>
                    </td>
                    <td class="align-middle text-center">
                      <span v-if="usuario.endereco_ip">
                        <span
                          v-if="!usuario.country_name"
                          class="btn btn-sm btn-primary"
                          @click="buscaLocalizacaoIP(usuario)"
                        >
                          <small> Buscar localização </small>
                        </span>
                        <div v-else>
                          <p class="m-0">
                            <b>País:</b> {{ usuario.country_name }}
                          </p>
                          <p class="m-0"><b>Estado:</b> {{ usuario.region }}</p>
                          <p class="m-0"><b>Cidade:</b> {{ usuario.city }}</p>
                          <small
                            class="text-secondary"
                          >Obs: informações não precisas</small>
                        </div>
                      </span>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td
                      class="text-center"
                      colspan="10"
                    >
                      Nenhuma estatísticas encontrada
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-12 text-center mt-4">
              <Pagination
                :page-size="5"
                :items="fastEstatisticasLive"
                @changePage="pageOfEstatisticasLive = $event"
              />
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalGravacaoLive"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Gravação da live</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalGravacaoLive')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12 mt-4">
              <label>link da gravação</label>
              <input
                v-model="fastLiveEditar.video_registro"
                type="text"
                class="form-control"
                placeholder="Cole o link da gravação aqui"
                @focus="fastLiveEditar.alteracao_pendente = true"
              >
            </div>
            <div class="col-12 mt-4">
              <button
                v-if="fastLiveEditar.alteracao_pendente"
                class="btn btn-sm btn-primary"
                @click="insereVideoRegistro()"
              >
                Salvar gravação
              </button>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalAnexosLive"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Anexos da live</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalAnexosLive')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <label> <strong>Novo anexo</strong> </label>
              <input
                v-model="fastLiveNovoAnexo.descricao"
                type="text"
                class="form-control"
                maxlength="60"
                placeholder="Insira uma descrição para o anexo"
              >
            </div>
            <div
              v-show="fastLiveNovoAnexo.descricao.length"
              class="col-12 mt-2"
            >
              <uploader
                :file-status-text="statusText"
                :options="optionsArquivo"
                class="uploader-example"
                @file-success="fileSuccess"
                @file-added="fileValidation"
              >
                <uploader-unsupport />
                <uploader-drop>
                  <p class="aluno_font_color">
                    Arraste um arquivo aqui ou
                  </p>
                  <uploader-btn
                    :attrs="restrictArquivo"
                  >
                    Clique aqui para selecionar
                  </uploader-btn>
                </uploader-drop>
                <uploader-list />
              </uploader>
            </div>
            <div class="col-12 mt-4">
              <div class="text-center">
                <h6>Lista de anexos</h6>
              </div>
              <ul
                v-if="fastLiveAnexos.length"
                class="list-group"
              >
                <li
                  v-for="arquivo in fastLiveAnexos"
                  :key="arquivo.id_live_arquivo"
                  class="list-group-item d-flex justify-content-between"
                >
                  <a
                    :href="arquivo.path"
                    download
                  >{{
                    arquivo.descricao ? arquivo.descricao : "Sem descrição"
                  }}</a>
                  <button
                    class="btn btn-sm btn-danger"
                    @click="excluirAnexoLive(arquivo)"
                  >
                    <small>Excluir</small>
                  </button>
                </li>
              </ul>
              <ul
                v-else
                class="list-group"
              >
                <li class="list-group-item">
                  Nenhum anexo
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <notifications
      group="foo"
      :close-on-click="false"
      position="bottom right"
      @onClick="
        (item) => {
          console.log('Teste');
        }
      "
    />
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import Pagination from "../components/Pagination";

import { FunctionalCalendar } from "vue-functional-calendar";

export default {
  name: "HomeInternoAdminLives",
  components: {
    Pagination,
    FunctionalCalendar,
  },
  mixins: [methods],
  data: function () {
    return {
      //ImageSiteFormEffect01,
      nome_plataforma: "",
      banner_plataforma: "",
      logo_plataforma: "",
      modalWidthSm: this.isMobile() ? "80%" : "30%",
      modalWidthMd: this.isMobile() ? "90%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",
      // Live
      fastLiveTotal: [],
      fastLiveFiltro: [],
      pageOfLive: [],
      fastLiveNova: {
        id_live: 0,
        API_TOKEN: "",
        nr_maximo_sala: "",
        data_live: "",
        hora_live: "",
        id_usuario_tutor: "",
        id_usuario_moderador: "",
        detalhes: "",
        titulo_live: "",
        id_plataforma: 0,
        status: "",
        video_registro: "",
        duracao_live: "",
        acesso: "R",
        id_usuario_responsavel: 0,
        tipo: "J",
        participantes: [],
      },
      fastLiveEditar: {
        id_live: "",
        API_TOKEN: "",
        nr_maximo_sala: "",
        data_live: "",
        hora_live: "",
        id_usuario_tutor: "",
        id_usuario_moderador: "",
        detalhes: "",
        titulo_live: "",
        id_plataforma: 0,
        status: "",
        video_registro: "",
        duracao_live: "",
        acesso: "R",
        id_usuario_responsavel: "",
        tipo: "",
        participantes: [],
      },
      fastLiveExcluir: {
        id_live: "",
        API_TOKEN: "",
        nr_maximo_sala: "",
        data_live: "",
        hora_live: "",
        id_usuario_tutor: "",
        id_usuario_moderador: "",
        detalhes: "",
        titulo_live: "",
        id_plataforma: 0,
        status: "",
        video_registro: "",
        duracao_live: "",
        acesso: "R",
        id_usuario_responsavel: "",
        tipo: "",
        participantes: [],
      },
      // Lista de usuarios
      usuariosTotal: [],
      usuariosFiltro: [],
      pageOfUsuarios: [],
      usuariosTotalEditar: [],
      usuariosFiltroEditar: [],
      pageOfUsuariosEditar: [],
      // Parametros do usuarios
      fastUsuarioParametro: {
        id_usuario: "",
        image: "",
        url_zoom: "",
      },
      // Calendário
      calendarData: {},
      calendarConfigs: {
        sundayStart: true,
        dateFormat: "dd/mm/yyyy",
        isDatePicker: true,
        isDateRange: false,
        markedDates: [],
      },
      calendarFiltroAtivo: false,
      modalWidth: "80%",
      // Estatisticas
      fastEstatisticasLive: [],
      pageOfEstatisticasLive: [],
      // Anexos
      fastLiveAnexos: [],
      fastLiveNovoAnexo: {
        id_live: "",
        descricao: "",
      },
      // Upload arquivo
      optionsArquivo: {
        target:
          settings.endApiFastEad + "api/fast_plataforma_live_arquivo/upload",
        testChunks: false,
        chunkSize: 1024 * 1024 * 100, // 100MB
        singleFile: true,
        query: {
          id_plataforma: this.$route.params.id_plataforma,
          id_live: "",
          descricao: "Sem descrição",
        },
        method: "POST",
        headers: { Authorization: "Bearer " + this.retornaToken() },
        categoryMap: {
          document: [
            "pdf",
            "odt",
            "doc",
            "docx",
            "ppt",
            "pptx",
            "gif",
            "jpg",
            "jpeg",
            "png",
            "rar",
          ],
        },
        parseTimeRemaining: function (timeRemaining, parsedTimeRemaining) {
          return parsedTimeRemaining
            .replace(/\syears?/, " anos")
            .replace(/\days?/, " dias")
            .replace(/\shours?/, " horas")
            .replace(/\sminutes?/, " minutos")
            .replace(/\sseconds?/, " segundos");
        },
      },
      restrictArquivo: {
        accept:
          "application/pdf,application/vnd.oasis.opendocument.text,application/msword,application/x-rar-compressed,application/vnd.ms-powerpoint,image/*",
      },
      statusText: {
        success: "Sucesso",
        error: "Erro",
        uploading: "Fazendo upload...",
        paused: "Upload pausado",
        waiting: "Aguardando...",
      },
    };
  },
  computed: {
    fastLiveNovaTipo() {
      return this.fastLiveNova.tipo;
    },
  },
  watch: {
    fastLiveNovaTipo(value) {
      this.$toast.clear();
      if (value == "Z" && !this.fastUsuarioParametro.url_zoom) {
        this.$toast.open({
          message: "Clique aqui para configurar sua URL do Zoom",
          type: "error",
          duration: 60000,
          dismissible: true,
          position: "bottom-right",
          onClick: this.redirecionaPerfil,
        });
      }
    },
  },
  mounted: function () {
    if (this.$route.params.id_plataforma) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'administrador')
        .then(() => {
          this.getFastLive();
          this.getPlataformasUsuarios();
          this.getFastUsuarioParametro();
          this.$store.state.fastCarregando = false;
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    }
  },
  methods: {
    // Parametros do usuario
    async getFastUsuarioParametro() {
      try {
        let resp = await fetch(
          settings.endApiFastEad + "api/fast_usuario_parametro/lista",
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {
          this.fastUsuarioParametro = obj[0];
        } else {
          this.fastUsuarioParametro = {
            id_usuario: "",
            image: "",
            url_zoom: "",
          };
        }
      } catch (e) {
        console.log("Erro", e);
      }
    },
    // Live
    async getFastLive() {
      this.calendarFiltroAtivo = false;
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_plataforma_live/lista_admin_nova?id_plataforma=" +
            this.$route.params.id_plataforma +
            "&id_usuario_tutor=0&id_usuario_moderador=0&id_usuarios_participantes=&data_live=&hora_live=&id_usuario_responsavel=0&acesso=&tipo=",
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {
          let lives = Array.from(new Set(obj.map((a) => a.id_live))).map(
            (id_live) => {
              return obj.find((a) => a.id_live === id_live);
            }
          );
          lives.forEach((live, index) => {
            // Calendário
            let dataLive = live.data_live.split("T")[0];
            dataLive =
              parseInt(dataLive.split("-")[2]) +
              "/" +
              parseInt(dataLive.split("-")[1]) +
              "/" +
              parseInt(dataLive.split("-")[0]);
            if (live.status == "A")
              this.calendarConfigs.markedDates.push({
                date: dataLive,
                class: "cal-live-agendada",
              });
            else if (live.status == "E")
              this.calendarConfigs.markedDates.push({
                date: dataLive,
                class: "cal-live-andamento",
              });
            live.data_formatada = dataLive;

            if (!live.participantes) live.participantes = [];
            obj.forEach((p, index2) => {
              if (live.id_live == p.id_live)
                live.participantes.push(p.id_usuario);
            });
          });
          this.fastLiveTotal = lives;
          this.fastLiveFiltro = lives;
        } else {
          this.fastLiveTotal = [];
          this.fastLiveFiltro = [];
        }
        //console.log("getFastLive", this.fastLiveTotal);
      } catch (e) {
        console.log("Erro", e);
      }
    },
    redirecionaPerfil() {
      window.location.href = "/meu-perfil";
      console.log("Clicou");
    },
    // Usuários
    async getPlataformasUsuarios() {
      let param = `id_plataforma=${this.$route.params.id_plataforma}&administrador=&professor=&aluno=&tesouraria=&secretario=&responsavel=`
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_plataforma_usuario/lista_usuarios_plataforma?" + param,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        //console.log("getPlataformasUsuarios", obj);
        if (obj.length > 0) {
          obj.forEach((e) => {
            if (!e.nome_usuario) e.nome_usuario = "Não cadastrado";
          });

          this.usuariosTotal = obj;
          this.usuariosFiltro = obj;
          this.usuariosTotalEditar = obj;
          this.usuariosFiltroEditar = obj;
        } else {
          this.usuariosTotal = [];
          this.usuariosFiltro = [];
          this.usuariosTotalEditar = [];
          this.usuariosFiltroEditar = [];
        }
      } catch (e) {
        console.log("Erro", e);
      }
    },
    filtraUsuario(e) {
      let text = e.target.value;
      this.usuariosFiltro = this.usuariosTotal.filter(
        (e) => e.nome_usuario.toLowerCase().indexOf(text.toLowerCase()) != "-1"
      );
    },
    adicionaParticipantesLive(usuario) {
      this.fastLiveNova.participantes.push(usuario);
    },
    verificaUsuarioIncluido(id_usuario) {
      let verificacao = this.fastLiveNova.participantes.filter(
        (e) => e.id_usuario == id_usuario
      );
      if (verificacao.length > 0) return true;
      else return false;
    },
    removeParticipantesLive(id_usuario) {
      this.fastLiveNova.participantes.forEach((u, index) => {
        if (u.id_usuario == id_usuario)
          this.fastLiveNova.participantes.splice(index, 1);
      });
    },
    filtraUsuarioEditar(e) {
      let text = e.target.value;
      this.usuariosFiltroEditar = this.usuariosTotalEditar.filter(
        (e) => e.nome_usuario.toLowerCase().indexOf(text.toLowerCase()) != "-1"
      );
    },
    adicionaParticipantesLiveEditar(usuario) {
      this.fastLiveEditar.participantes.push(usuario);
    },
    verificaUsuarioIncluidoEditar(id_usuario) {
      let verificacao = this.fastLiveEditar.participantes.filter(
        (e) => e.id_usuario == id_usuario
      );
      if (verificacao.length > 0) return true;
      else return false;
    },
    removeParticipantesLiveEditar(id_usuario) {
      this.fastLiveEditar.participantes.forEach((u, index) => {
        if (u.id_usuario == id_usuario)
          this.fastLiveEditar.participantes.splice(index, 1);
      });
    },
    async criarLive() {
      let erros = [];
      if (!this.fastLiveNova.id_usuario_tutor)
        erros.push("A escolha do tutor é obrigatória");
      if (!this.fastLiveNova.titulo_live)
        erros.push("O título da live é obrigatório");
      if (!this.fastLiveNova.detalhes)
        this.fastLiveNova.detalhes = "Sem detalhes";
      if (!this.fastLiveNova.data_live)
        erros.push("O data da live é obrigatória");
      if (!this.fastLiveNova.hora_live)
        erros.push("O hora da live é obrigatória");
      if (!this.fastLiveNova.duracao_live)
        erros.push("O duração da live é obrigatória");
      this.fastLiveNova.nr_maximo_sala = 0;
      if (!this.fastLiveNova.participantes.length)
        erros.push("Ao menos um participante é obrigatório");

      if (this.fastLiveNova.tipo == "Z" && !this.fastUsuarioParametro.url_zoom)
        erros.push(
          "É necessário configurar a URL do Zoom para pode agendar esse tipo de live"
        );

      if (this.fastLiveNova.tipo == "Z")
        this.fastLiveNova.API_TOKEN = this.fastUsuarioParametro.url_zoom;

      if (this.fastLiveNova.tipo == "M" && !this.fastLiveNova.API_TOKEN)
        erros.push("É necessário inserir a URL do Google Meet");

      if (erros.length) {
        erros.forEach((e) => {
          this.$notify({
            group: "foo",
            type: "error",
            duration: 5000,
            speed: 1000,
            title: "Erro",
            text: e,
          });
        });
      } else {
        this.fastLiveNova.id_usuario_moderador = this.fastLiveNova.id_usuario_tutor;
        this.fastLiveNova.id_plataforma = this.$route.params.id_plataforma;

        this.fastLiveNova.hora_live = this.fastLiveNova.hora_live.length != 5 ? this.fastLiveNova.hora_live : `${this.fastLiveNova.hora_live}:00`
        this.fastLiveNova.duracao_live = this.fastLiveNova.duracao_live.length != 5 ? this.fastLiveNova.duracao_live : `${this.fastLiveNova.duracao_live}:00`

        this.fastLiveNova.status = "A";
        let id_usuarios_participantes = this.fastLiveNova.participantes.map(
          (u) => u.id_usuario
        );
        let email_usuarios_participantes = this.fastLiveNova.participantes.map(
          (u) => u.email
        );
        let model = {
          fast_plataforma_live: this.fastLiveNova,
          id_usuarios_participantes: id_usuarios_participantes.join(),
          email_usuarios_participantes: email_usuarios_participantes.join(),
        };
        this.$store.state.fastCarregando = true;
        try {
          let resp = await fetch(
            settings.endApiFastEad + "api/fast_plataforma_live/insere_nova",
            this.fastAjaxOptions("POST", JSON.stringify(model))
          );

          let json = await resp.json();
          let obj = Array.from(json);
          this.$store.state.fastCarregando = false;
          this.getFastLive();
          this.$notify({
            group: "foo",
            type: "success",
            duration: 5000,
            speed: 1000,
            title: "Status",
            text: "Live agendada com sucesso",
          });
          this.fastLiveNova = {
            id_live: "",
            API_TOKEN: "",
            nr_maximo_sala: "",
            data_live: "",
            hora_live: "",
            id_usuario_tutor: "",
            id_usuario_moderador: "",
            detalhes: "",
            titulo_live: "",
            id_plataforma: 0,
            status: "",
            video_registro: "",
            duracao_live: "",
            acesso: "R",
            id_usuario_responsavel: "",
            tipo: "J",
            participantes: [],
          };
          this.hideModal("modalCriarLive");
        } catch (e) {
          this.$store.state.fastCarregando = false;
          console.log("Erro", e);
        }
      }
    },
    async editarLive() {
      let erros = [];
      if (!this.fastLiveEditar.id_usuario_tutor)
        erros.push("A escolha do tutor é obrigatória");
      if (!this.fastLiveEditar.titulo_live)
        erros.push("O título da live é obrigatório");
      if (!this.fastLiveEditar.detalhes)
        this.fastLiveEditar.detalhes = "Sem detalhes";
      if (!this.fastLiveEditar.data_live)
        erros.push("O data da live é obrigatória");
      if (!this.fastLiveEditar.hora_live)
        erros.push("O hora da live é obrigatória");
      if (!this.fastLiveEditar.duracao_live)
        erros.push("O duração da live é obrigatória");
      this.fastLiveEditar.nr_maximo_sala = 0;
      if (!this.fastLiveEditar.participantes.length)
        erros.push("Ao menos um participante é obrigatório");
      if (
        this.fastLiveEditar.tipo == "Z" &&
        !this.fastUsuarioParametro.url_zoom
      )
        erros.push(
          "É necessário configurar a URL do Zoom para pode agendar esse tipo de live"
        );
      if (this.fastLiveEditar.tipo == "M" && !this.fastLiveEditar.API_TOKEN)
        erros.push("É necessário inserir a URL do Google Meet");

      if (erros.length) {
        erros.forEach((e) => {
          this.$notify({
            group: "foo",
            type: "error",
            duration: 5000,
            speed: 1000,
            title: "Erro",
            text: e,
          });
        });
      } else {
        this.fastLiveEditar.id_usuario_moderador = this.fastLiveEditar.id_usuario_tutor;
        this.fastLiveEditar.id_plataforma = this.$route.params.id_plataforma;
        this.fastLiveEditar.hora_live = this.fastLiveEditar.hora_live.length != 5 ? this.fastLiveEditar.hora_live : `${this.fastLiveEditar.hora_live}:00`
        //this.fastLiveEditar.status = "A";
        let id_usuarios_participantes = this.fastLiveEditar.participantes.map(
          (u) => u.id_usuario
        );
        let model = {
          fast_plataforma_live: this.fastLiveEditar,
          id_usuarios_participantes: id_usuarios_participantes.join(),
        };
        this.$store.state.fastCarregando = true;
        try {
          let resp = await fetch(
            settings.endApiFastEad + "api/fast_plataforma_live/atualiza_nova",
            this.fastAjaxOptions("POST", JSON.stringify(model))
          );

          let json = await resp.json();
          let obj = Array.from(json);
          this.$store.state.fastCarregando = false;
          this.getFastLive();
          this.$notify({
            group: "foo",
            type: "success",
            duration: 5000,
            speed: 1000,
            title: "Status",
            text: "Live atualizada com sucesso",
          });
          this.fastLiveEditar = {
            id_live: "",
            API_TOKEN: "",
            nr_maximo_sala: "",
            data_live: "",
            hora_live: "",
            id_usuario_tutor: "",
            id_usuario_moderador: "",
            detalhes: "",
            titulo_live: "",
            id_plataforma: 0,
            status: "",
            video_registro: "",
            duracao_live: "",
            acesso: "R",
            id_usuario_responsavel: "",
            tipo: "J",
            participantes: [],
          };
          this.hideModal("modalEditarLive");
        } catch (e) {
          this.$store.state.fastCarregando = false;
          console.log("Erro", e);
        }
      }
    },
    async insereVideoRegistro() {
      this.$store.state.fastCarregando = true;

      const fast_plataforma_live = {
        id_live: this.fastLiveEditar.id_live,
        video_registro: this.fastLiveEditar.video_registro,
      };

      try {
        let resp = await fetch(
          settings.endApiFastEad + "api/fast_plataforma_live/atualiza_gravacao",
          this.fastAjaxOptions("POST", JSON.stringify(fast_plataforma_live))
        );

        let json = await resp.json();
        let obj = Array.from(json);
        this.$store.state.fastCarregando = false;
        this.getFastLive();
        this.$notify({
          group: "foo",
          type: "success",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text: "Live atualizada com sucesso",
        });
        this.fastLiveEditar = {
          id_live: "",
          API_TOKEN: "",
          nr_maximo_sala: "",
          data_live: "",
          hora_live: "",
          id_usuario_tutor: "",
          id_usuario_moderador: "",
          detalhes: "",
          titulo_live: "",
          id_plataforma: 0,
          status: "",
          video_registro: "",
          duracao_live: "",
          acesso: "R",
          id_usuario_responsavel: "",
          tipo: "J",
          participantes: [],
        };
        this.hideModal("modalGravacaoLive");
      } catch (e) {
        this.$store.state.fastCarregando = false;
        console.log("Erro", e);
      }
    },
    async finalizarLive(acao) {
      this.fastLiveEditar.status = acao;
      let model = {
        fast_plataforma_live: this.fastLiveEditar,
        id_usuarios_participantes: "",
      };

      console.log("model", model)
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_plataforma_live/finaliza_live_nova",
          this.fastAjaxOptions("POST", JSON.stringify(model))
        );
        let json = await resp.json();
        console.log("finalizarLive", json)
        let obj = Array.from(json);
        this.hideModal("modalFinalizarLive");
        this.hideModal("modalCancelarLive");
      } catch (e) {
        console.log("Erro", e);
      }
    },
    exibeModalEditarLive(live) {
      console.log("Live", live);
      console.log("usuariosTotalEditar", this.usuariosTotalEditar);
      let participantes = [];
      this.usuariosTotalEditar.forEach((u) => {
        live.participantes.forEach((e, index) => {
          if (e.id_usuario) {
            if (e.id_usuario == u.id_usuario) {
              participantes.push(u);
            }
          } else {
            if (e == u.id_usuario) {
              participantes.push(u);
            }
          }
        });
      });
      live.participantes = participantes;
      this.fastLiveEditar = live;

      this.showModal("modalEditarLive");
    },
    exibeModalExcluirLive(live) {
      this.fastLiveExcluir = live;
      this.showModal("modalExcluirLive");
    },
    exibeModalFinalizarLive(live) {
      this.fastLiveEditar = live;
      this.showModal("modalFinalizarLive");
    },
    exibeModalCancelarLive(live) {
      this.fastLiveEditar = live;
      this.showModal("modalCancelarLive");
    },
    exibeModalEstatisticasLive(live) {
      this.$store.state.fastCarregando = true;
      this.getLiveEstatisticas(live).then((estatisticas) => {
        this.fastEstatisticasLive = estatisticas;
        this.showModal("modalEstatisticasLive");
        this.$store.state.fastCarregando = false;
      });
    },
    exibeModalGravacaoLive(live) {
      this.fastLiveEditar = live;
      this.showModal("modalGravacaoLive");
    },
    exibeModalAnexosLive(live) {
      this.$store.state.fastCarregando = true;
      this.getLiveAnexos(live)
        .then((arquivos) => {
          this.fastLiveEditar = live;
          this.fastLiveAnexos = arquivos;
          this.showModal("modalAnexosLive");
          this.$store.state.fastCarregando = false;
        })
        .catch((e) => {
          console.log(e);
          this.$store.state.fastCarregando = false;
        });
    },
    fileValidation(e) {
      this.optionsArquivo.query.id_live = this.fastLiveEditar.id_live;
      this.optionsArquivo.query.descricao = this.fastLiveNovoAnexo.descricao;
      return false;
    },
    fileSuccess(rootFile, file, message, chunk) {
      let result = JSON.parse(JSON.parse(message));
      if (!result.error) {
        console.log("fileSuccess", result);
        this.exibeModalAnexosLive(this.fastLiveEditar);
      }
    },
    async getLiveAnexos(live) {
      return new Promise(async (resolve, reject) => {
        try {
          let resp = await fetch(
            settings.endApiFastEad +
              "api/fast_plataforma_live_arquivo/lista?id_live=" +
              live.id_live,
            this.fastAjaxOptions("GET")
          );
          let json = await resp.json();

          resolve(json);
        } catch (e) {
          reject(e);
        }
      });
    },
    async getLiveEstatisticas(live) {
      return new Promise(async (resolve, reject) => {
        try {
          let resp = await fetch(
            settings.endApiFastEad +
              "api/fast_plataforma_live/lista_estatisticas?id_live=" +
              live.id_live,
            this.fastAjaxOptions("GET")
          );
          let json = await resp.json();
          let obj = Array.from(json);
          if (json.length) {
            obj.forEach((e) => {
              e.country_name = "";
              e.region = "";
              e.city = "";
            });
            resolve(json);
          } else {
            reject(false);
          }
        } catch (e) {
          reject(e);
        }
      });
    },
    async buscaLocalizacaoIP(usuario) {
      try {
        let resp = await fetch(
          "https://ipapi.co/" + usuario.endereco_ip + "/json/",
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        if (!json.error) {
          usuario.country_name = json.country_name;
          usuario.region = json.region;
          usuario.city = json.city;
        } else {
          usuario.country_name = "Não identificado";
          usuario.region = "Não identificado";
          usuario.city = "Não identificado";
        }
      } catch (e) {
        console.log(e);
      }
    },
    detectaBrowser(user_agent) {
      var isOpera =
        (!!window.opr && !!opr.addons) ||
        !!window.opera ||
        navigator.userAgent.indexOf(" OPR/") >= 0;
      // Firefox 1.0+
      var isFirefox = typeof InstallTrigger !== "undefined";
      // Safari 3.0+ "[object HTMLElementConstructor]"
      var isSafari =
        /constructor/i.test(window.HTMLElement) ||
        (function (p) {
          return p.toString() === "[object SafariRemoteNotification]";
        })(
          !window["safari"] ||
            (typeof safari !== "undefined" && safari.pushNotification)
        );
      // Internet Explorer 6-11
      var isIE = /*@cc_on!@*/ false || !!document.documentMode;
      // Edge 20+
      var isEdge = !isIE && !!window.StyleMedia;
      // Chrome 1 - 79
      var isChrome =
        !!window.chrome &&
        (!!window.chrome.webstore || !!window.chrome.runtime);
      // Edge (based on chromium) detection
      var isEdgeChromium = isChrome && navigator.userAgent.indexOf("Edg") != -1;
      // Blink engine detection
      var isBlink = (isChrome || isOpera) && !!window.CSS;

      if (isOpera) return "Opera";
      else if (isFirefox) return "Firefox";
      else if (isSafari) return "Safari";
      else if (isIE) return "Internet Explorer";
      else if (isEdge) return "Edge";
      else if (isChrome) return "Chrome";
      else return "Não identificado";
    },
    retornaTotalUsuarios() {
      if (this.fastEstatisticasLive.length) {
        let totalParticipantes = 0;
        this.fastEstatisticasLive.forEach((u) => {
          if (u.hora_entrou) totalParticipantes++;
        });
        return (
          totalParticipantes +
          " de " +
          this.fastEstatisticasLive.length +
          " usuários tiveram seus acessos registrados."
        );
      }
    },
    async excluirLive() {
      this.fastLiveExcluir.id_plataforma = this.$route.params.id_plataforma;
      let model = {
        fast_plataforma_live: this.fastLiveExcluir,
        id_usuarios_participantes: "",
      };
      try {
        let resp = await fetch(
          settings.endApiFastEad + "api/fast_plataforma_live/exclui_nova",
          this.fastAjaxOptions("POST", JSON.stringify(model))
        );
        let json = await resp.json();
        let obj = Array.from(json);
        console.log(json);
        this.getFastLive();
        this.$notify({
          group: "foo",
          type: "success",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text: "Live excluída com sucesso",
        });
        this.hideModal("modalExcluirLive");
      } catch (e) {
        console.log("Erro", e);
      }
    },
    async excluirAnexoLive(arquivo) {
      const fast_plataforma_live_arquivo = {
        id_live_arquivo: arquivo.id_live_arquivo,
        id_live: arquivo.id_live,
      };

      try {
        let resp = await fetch(
          settings.endApiFastEad + "api/fast_plataforma_live_arquivo/exclui",
          this.fastAjaxOptions(
            "POST",
            JSON.stringify(fast_plataforma_live_arquivo)
          )
        );
        let json = await resp.json();
        let obj = Array.from(json);
        this.exibeModalAnexosLive(this.fastLiveEditar);
        this.$notify({
          group: "foo",
          type: "success",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text: "Anexo excluído com sucesso",
        });
      } catch (e) {
        console.log("Erro", e);
      }
    },
    // Calendário
    filtraDiaLive(cal) {
      this.fastLiveFiltro = this.fastLiveTotal.filter(
        (e) => e.data_formatada == cal.date
      );
      this.calendarFiltroAtivo = cal.date;
      this.hideModal("modalCalendarioLive");
    },
  },
};
</script>

<style scope>
.cal-live-agendada {
  background-color: #ffc107 !important;
}
.cal-live-andamento {
  background-color: #28a745 !important;
}
</style>
